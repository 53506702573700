// frontend/src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <h2 style={{ textAlign: 'center' }}>Please log in to access this page.</h2>;
  }

  return children;
};

export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from '../api/firebaseConfig';
import axios from 'axios';
import { updateUserProfile } from '../api/api';
import AvatarSelector from './AvatarSelector'; // Import AvatarSelector component
import defaultAvatar from './default-avatar.png';
import './Menu.css';

const API_URL = 'https://us-central1-meme-ranking.cloudfunctions.net/api';

const Menu = ({ isOpen, onToggle, onNavigate, user, onLogout, avatarUrl, onAvatarUpdate, onAvatarClick }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isAvatarSelectorOpen, setIsAvatarSelectorOpen] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null); // Tracks which menu is expanded (Game or Leaderboard)

  useEffect(() => {
    const fetchUserAvatar = async () => {
      if (user && !avatarUrl) {
        try {
          const idToken = await user.getIdToken();
          const response = await axios.get(`${API_URL}/user`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          onAvatarUpdate(response.data.avatarUrl || defaultAvatar);
        } catch (error) {
          console.error('Error fetching user avatar:', error);
        }
      }
    };

    fetchUserAvatar();
  }, [user, avatarUrl, onAvatarUpdate]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1023);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const firebaseUser = result.user;

      if (firebaseUser.email && firebaseUser.email.endsWith('@bosromania.ro')) {
        const idToken = await firebaseUser.getIdToken(true);
        await axios.post(
          `${API_URL}/auth/login`,
          { email: firebaseUser.email },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        onNavigate('/');
      } else {
        await auth.signOut();
        alert("Access denied: You must use a @bosromania.ro email address.");
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error.message);
    }
  };

  const toggleSubmenu = (menu) => {
    setExpandedMenu((prev) => (prev === menu ? null : menu)); // Toggle submenu
  };

  const handleNavigation = (path) => {
    onNavigate(path);
    onToggle();
    setExpandedMenu(null); // Close submenu after navigation
  };

  const handleAvatarClick = () => setIsAvatarSelectorOpen(true);
  const handleSelectorClose = () => setIsAvatarSelectorOpen(false);
  const handleMouseLeave = () => {
    if (!isMobile) onToggle();
  };

  return (
    <div className="menu-container" style={{ zIndex: isOpen ? 1000 : 0 }}>
      <div className="icon-button toggle-icon" onClick={onToggle}>
        &#9776;
      </div>
      <div className={`side-menu ${isOpen ? 'open' : 'closed'}`} onMouseLeave={handleMouseLeave}>
        <div className="menu-item" onClick={() => handleNavigation('/')}>Home</div>

        <div className="menu-item" onClick={() => toggleSubmenu('game')}>
          Game
          <span className={`submenu-indicator ${expandedMenu === 'game' ? 'expanded' : ''}`}></span>
        </div>
        {expandedMenu === 'game' && (
          <div className="submenu">
            <div className="submenu-item" onClick={() => handleNavigation('/matchup?category=all-time')}>
              All-Time
            </div>
            <div className="submenu-item" onClick={() => handleNavigation('/matchup?category=meme-of-the-month')}>
              Meme of the Month
            </div>
          </div>
        )}

        <div className="menu-item" onClick={() => toggleSubmenu('leaderboard')}>
          Leaderboard
          <span className={`submenu-indicator ${expandedMenu === 'leaderboard' ? 'expanded' : ''}`}></span>
        </div>
        {expandedMenu === 'leaderboard' && (
          <div className="submenu">
            <div className="submenu-item" onClick={() => handleNavigation('/leaderboard?category=all-time')}>
              All-Time
            </div>
            <div className="submenu-item" onClick={() => handleNavigation('/leaderboard?category=meme-of-the-month')}>
              Meme of the Month
            </div>
          </div>
        )}

        {user ? (
          <div className="user-section">
            <div className="user-info">
              <div className="avatar-container" onClick={handleAvatarClick}>
                <img
                  src={avatarUrl || defaultAvatar}
                  alt="User Avatar"
                  className="current-avatar"
                />
              </div>
              <p>Hello, {user.displayName}</p>
            </div>

            <button onClick={() => { onLogout(); onToggle(); }}>Logout</button>
          </div>
        ) : (
          <div className="auth-section">
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
          </div>
        )}
      </div>

      {/* Full-screen Avatar Selector */}
      {isAvatarSelectorOpen && (
        <AvatarSelector
          currentAvatar={avatarUrl || defaultAvatar}
          onAvatarChange={(newAvatarUrl) => onAvatarUpdate(newAvatarUrl)}
          onClose={handleSelectorClose}
        />
      )}
    </div>
  );
};

export default Menu;

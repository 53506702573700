import axios from 'axios';
import { auth } from './firebaseConfig'; // Ensure you import the Firebase auth

// Update this to your deployed backend URL
const API_URL = 'https://us-central1-meme-ranking.cloudfunctions.net/api'; // Replace with your actual backend URL

// Fetch memes from the backend
export const fetchMemes = async () => {
    try {
        const response = await axios.get(`${API_URL}/memes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching memes:', error);
        throw error; // Allow caller to handle error
    }
};

// Fetch avatars from the backend
export const fetchAvatars = async () => {
    try {
        const response = await axios.get(`${API_URL}/avatars`);
        return response.data;
    } catch (error) {
        console.error('Error fetching avatars:', error);
        throw error; // Allow caller to handle error
    }
};

// Fetch user data from the backend
export const fetchUser = async () => {
    try {
        const token = await auth.currentUser.getIdToken();
        const response = await axios.get(`${API_URL}/user`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};

// Update user profile with new data (e.g., avatar URL)
export const updateUserProfile = async (profileData) => {
    try {
        const token = await auth.currentUser.getIdToken(true); // Force refresh the token

        const response = await axios.put(`${API_URL}/user/profile`, profileData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status !== 200) {
            throw new Error(`Failed to update profile: ${response.status} ${response.statusText}`);
        }
        return response.data;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};


// MobileView.js

import React from 'react';

const MobileView = ({
  currentMatchup,
  eloChange,
  showEloChange,
  winnerId,
  selectedMeme,
  voteConfirmed,
  votingLocked,
  votingId,
  onMemeClick,
  onExitPreview,
  onVoteClick,
  onConfirmVote
}) => {
  return (
    <div className="game-container">
      {currentMatchup.length === 2 ? (
        <>
          <div className="matchup">
            {/* Meme 1 Container */}
            <div className="meme-container">
              <div className="meme-box" onClick={() => onMemeClick(currentMatchup[0])}>
                {currentMatchup[0].fileType === 'image' ? (
                  <img src={currentMatchup[0].fileUrl} alt={`Meme ${currentMatchup[0].id}`} />
                ) : (
                  <video src={currentMatchup[0].fileUrl} controls={false} autoPlay loop muted className="video-meme" />
                )}
                <p>
                  {voteConfirmed ? `SCORE: ${currentMatchup[0].eloScore}` : 'SCORE: ?'}
                  {voteConfirmed && showEloChange && (
                    <span className={currentMatchup[0].id === winnerId ? 'elo-up' : 'elo-down'}>
                      {currentMatchup[0].id === winnerId ? ` +${eloChange.winner}` : ` ${eloChange.loser}`}
                    </span>
                  )}
                </p>
              </div>
            </div>

            {/* Mobile-only: Center vote buttons between memes */}
            <div className="vote-divider">
                <div class="vote-wrapper">
                <button
                    className="vote-button"
                    onClick={() => onVoteClick(currentMatchup[0].id)}
                    disabled={votingLocked}
                >
                    VOTE!
                </button>
                {votingId === currentMatchup[0].id && (
                    <button className="confirm-button" onClick={onConfirmVote}>
                    Sure?
                    </button>
                )}
            </div>

              <div class="vote-wrapper">
                <button
                    className="vote-button"
                    onClick={() => onVoteClick(currentMatchup[1].id)}
                    disabled={votingLocked}
                >
                    VOTE!
                </button>
                {votingId === currentMatchup[1].id && (
                    <button className="confirm-button" onClick={onConfirmVote}>
                        Sure?
                    </button>
                )}
              </div>
            </div>

            {/* Meme 2 Container */}
            <div className="meme-container">
              <div className="meme-box" onClick={() => onMemeClick(currentMatchup[1])}>
                {currentMatchup[1].fileType === 'image' ? (
                  <img src={currentMatchup[1].fileUrl} alt={`Meme ${currentMatchup[1].id}`} />
                ) : (
                  <video src={currentMatchup[1].fileUrl} controls={false} autoPlay loop muted className="video-meme" />
                )}
                <p>
                  {voteConfirmed ? `SCORE: ${currentMatchup[1].eloScore}` : 'SCORE: ?'}
                  {voteConfirmed && showEloChange && (
                    <span className={currentMatchup[1].id === winnerId ? 'elo-up' : 'elo-down'}>
                      {currentMatchup[1].id === winnerId ? ` +${eloChange.winner}` : ` ${eloChange.loser}`}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h2>Loading Matchup...</h2>
      )}

      {selectedMeme && (
        <div className="meme-preview-overlay" onClick={onExitPreview}>
          <div className="meme-preview-content" onClick={(e) => e.stopPropagation()}>
            {selectedMeme.fileType === 'video' ? (
              <video src={selectedMeme.fileUrl} className="preview-meme" controls autoPlay />
            ) : (
              <img src={selectedMeme.fileUrl} alt={selectedMeme.name} className="preview-meme" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileView;

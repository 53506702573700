import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LeaderboardPage.css';

const CACHE_KEY = 'memesCache';
const CURRENT_PAGE_KEY = 'currentPage';
const LAST_ACCESSED_KEY = 'lastAccessedTime';
const PAGE_PREF_TIME_LIMIT = 5 * 60 * 1000; // 5 minutes in milliseconds

const LeaderboardPage = () => {
  const [memes, setMemes] = useState([]);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [isTouchingMeme, setIsTouchingMeme] = useState(false);
  const [lastTap, setLastTap] = useState(0);
  const memesPerPage = 18;
  const [currentPage, setCurrentPage] = useState(() => {
    const lastAccessed = localStorage.getItem(LAST_ACCESSED_KEY);
    const savedPage = parseInt(localStorage.getItem(CURRENT_PAGE_KEY), 10);

    // If the page preference is older than 5 minutes or if there's no saved page, use default page 1
    if (lastAccessed && new Date().getTime() - parseInt(lastAccessed, 10) < PAGE_PREF_TIME_LIMIT && savedPage > 0) {
      return savedPage;
    } else {
      return 1; // Default to page 1
    }
  });

  const fetchMemes = async () => {
    const cachedMemes = localStorage.getItem(CACHE_KEY);
    const cachedMemesParsed = cachedMemes ? JSON.parse(cachedMemes) : [];

    // Always fetch fresh memes from API
    console.log('Fetching memes from API');
    try {
        const response = await axios.get('https://us-central1-meme-ranking.cloudfunctions.net/api/memes');
        const fetchedMemes = response.data;

        // Compare the fresh memes with the cached memes
        if (JSON.stringify(cachedMemesParsed) !== JSON.stringify(fetchedMemes)) {
            // If the data has changed, update cache and state
            console.log('Data has changed, updating cache');
            localStorage.setItem(CACHE_KEY, JSON.stringify(fetchedMemes));
            setMemes(fetchedMemes);
        } else {
            // If the data hasn't changed, load memes from cache
            console.log('No changes in data, loaded from cache');
            setMemes(cachedMemesParsed);
        }
    } catch (error) {
        console.error('Error fetching memes:', error);

        // If the API call fails, try to load memes from cache
        if (cachedMemes) {
            console.log('Loading memes from cache due to API error');
            setMemes(cachedMemesParsed);
        }
    }
};

// Called whenever the page refreshes (or initially when component mounts)
useEffect(() => {
    fetchMemes(); // Fetch memes from API
}, []);

  useEffect(() => {
    // Save the current page and timestamp to localStorage whenever it changes
    localStorage.setItem(CURRENT_PAGE_KEY, currentPage);
    localStorage.setItem(LAST_ACCESSED_KEY, new Date().getTime().toString());
  }, [currentPage]);

  const indexOfLastMeme = currentPage * memesPerPage;
  const indexOfFirstMeme = indexOfLastMeme - memesPerPage;
  const currentMemes = memes.slice(indexOfFirstMeme, indexOfLastMeme);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  };

  const handleMemeClick = (meme) => {
    setSelectedMeme(meme);
    document.body.style.overflow = 'hidden';
  };

  const handleExitPreview = () => {
    if (selectedMeme) {
      const memeIndex = memes.indexOf(selectedMeme);
      const newPage = Math.floor(memeIndex / memesPerPage) + 1;
      setCurrentPage(newPage);
    }
    setSelectedMeme(null);
    document.body.style.overflow = ''; // Re-enable scrolling on the page
  };

  const handleOverlayClick = (e) => {
    // Double-tap only for mobile
    if (window.innerWidth < 768 || window.innerHeight < 500) {
      const currentTime = new Date().getTime();
      if (currentTime - lastTap < 300) {
        handleExitPreview();
      }
      setLastTap(currentTime);
    } else { // Desktop view
      if (e.target.classList.contains('meme-preview-overlay')) {
        e.stopPropagation();
        e.preventDefault();
        handleExitPreview();
      }
    }
  };

  const scrollMemePreview = (direction) => {
    if (!selectedMeme) return;

    const currentIndex = memes.indexOf(selectedMeme);
    const newIndex = currentIndex + direction;

    if (newIndex >= 0 && newIndex < memes.length) {
      setSelectedMeme(memes[newIndex]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedMeme) {
        if (event.key === 'ArrowUp') scrollMemePreview(-1);
        if (event.key === 'ArrowDown') scrollMemePreview(1);
        if (event.key === 'Escape') handleExitPreview(); // Exit preview on Escape key
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedMeme]);

  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchEndY = 0;
    let lastTapTime = 0;
    const tapThreshold = 10; // Max movement for a tap
    const doubleTapDelay = 300; // Max delay between taps for double-tap
    const swipeThreshold = 30; // Minimum distance for a swipe
  
    const handleTouchStart = (e) => {
      if (e.touches.length > 1) {
        return; // Ignore if more than one touch
      }

      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
      touchEndX = touchStartX;
      touchEndY = touchStartY;
      setIsTouchingMeme(true);
      e.preventDefault(); // Prevent subsequent click event
    };
  
    const handleTouchMove = (e) => {
      touchEndX = e.touches[0].clientX;
      touchEndY = e.touches[0].clientY;
    };
  
    const handleTouchEnd = (e) => {
      if (!selectedMeme) return;

      if (e.touches.length > 1) {
        return; // Ignore if more than one touch
      }
  
      const horizontalSwipeDistance = touchEndX - touchStartX;
      const verticalSwipeDistance = touchEndY - touchStartY;
  
      // Ignore touch events if touchEndY is too close to 0 (indicates incomplete touch)
      if (touchEndY < 50) {
        console.log("Touch end too low; ignoring swipe calculation.");
        setIsTouchingMeme(false);
        return;
      }
  
      // Determine if this was a tap
      const isTap =
      Math.abs(horizontalSwipeDistance) < tapThreshold &&
      Math.abs(verticalSwipeDistance) < tapThreshold;

    if (isTap) {
      const currentTime = new Date().getTime();
      const timeSinceLastTap = currentTime - lastTapTime;

      if(window.innerWidth > 768 && window.innerHeight > 500) {
        console.log("Detected tap on desktop; exiting preview.");
        handleExitPreview();
      }

      if (timeSinceLastTap < doubleTapDelay) {
        e.stopPropagation(); // Prevent the tap from propagating
        e.preventDefault(); // Prevent default behavior
        console.log("Detected double-tap; exiting preview.");
        handleExitPreview();
      }

      // Update lastTapTime for the next tap
      lastTapTime = currentTime;
    }

      // Horizontal swipe: close preview
      else if (
        Math.abs(horizontalSwipeDistance) > swipeThreshold &&
        Math.abs(horizontalSwipeDistance) > Math.abs(verticalSwipeDistance)
      ) {
        console.log("Horizontal swipe detected; exiting preview.");
        handleExitPreview();
      } 
      // Vertical swipe: scroll meme preview
      else if (Math.abs(verticalSwipeDistance) > swipeThreshold) {
        console.log("Vertical swipe detected; scrolling preview.");
        scrollMemePreview(verticalSwipeDistance > 0 ? -1 : 1);
      }
  
      setIsTouchingMeme(false);
    };
  
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [selectedMeme]);

  useEffect(() => {
    if (memes.length > 0) {
      const podiumItems = document.querySelectorAll('.podium');
  
      // Remove any existing animation classes
      podiumItems.forEach((item) => item.classList.remove('animate-podium'));
  
      // Set animation with delays: 1.5 seconds between each
      setTimeout(() => {
        podiumItems[1]?.classList.add('animate-podium'); // 1st place
      }, 0);
  
      setTimeout(() => {
        podiumItems[0]?.classList.add('animate-podium'); // 2nd place
      }, 750);
  
      setTimeout(() => {
        podiumItems[2]?.classList.add('animate-podium'); // 3rd place
      }, 1500);
    }
  }, [memes]);
  
  
  return (
    <div className="leaderboard-container">
      <div className="podium-container">
        {[
          memes[1], // 2nd place
          memes[0], // 1st place
          memes[2], // 3rd place
        ].map((meme, index) => (
          <div
            key={meme?.id || index}
            className={`podium podium-${index + 1} ${meme ? "animate-podium" : ""}`}
            onClick={() => meme && handleMemeClick(meme)}
          >
            <div className="podium-step">
              <div className="elo-score-podium">
                {index === 0 ? "2nd" : index === 1 ? "1st" : "3rd"} - SCORE: {meme?.eloScore || 'N/A'}
              </div>
              {meme ? (
                <div className="image-container">
                  {meme.fileType === 'video' ? (
                    <video 
                      src={meme.fileUrl} 
                      className="podium-image" 
                      controls={false} 
                      autoPlay 
                      loop 
                      muted
                      playsInline
                    />
                  ) : (
                    <img src={meme.fileUrl} alt={meme.name} className="podium-image" />
                  )}
                </div>
              ) : (
                <div className="image-container">
                  <p>No meme available</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <h2>Other Memes</h2>
      <div className="meme-grid">
        {currentMemes.slice(3).map((meme, index) => (
          <div key={meme.id} className="meme-item" onClick={() => handleMemeClick(meme)}>
            <div className="image-container">
              {meme.fileType === 'video' ? (
                <video 
                  src={meme.fileUrl} 
                  className="meme-image" 
                  controls={false} 
                  autoPlay 
                  loop 
                  muted
                  playsInline 
                />
              ) : (
                <img src={meme.fileUrl} alt={meme.name} className="meme-image" />
              )}
              <p className="elo-score">
                {getOrdinal(indexOfFirstMeme + index + 4)} - SCORE : {meme.eloScore}
              </p>
            </div>
            <p>{meme.name}</p>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: Math.ceil(memes.length / memesPerPage) }, (_, i) => (
          <button 
            key={i} 
            onClick={() => paginate(i + 1)} 
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>

      {selectedMeme && (
        <div className="meme-preview-overlay" onClick={handleOverlayClick}>
          <div className="meme-preview-content" onClick={(e) => e.stopPropagation()}>
            {selectedMeme.fileType === 'video' ? (
              <video src={selectedMeme.fileUrl} className="preview-meme" controls autoPlay loop />
            ) : (
              <img src={selectedMeme.fileUrl} alt={selectedMeme.name} className="preview-meme" />
            )}
            <div className="preview-elo-score">SCORE: {selectedMeme.eloScore}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardPage;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import './GamePage.css';

const GamePage = () => {
  const [currentMatchup, setCurrentMatchup] = useState([]);
  const [eloChange, setEloChange] = useState({ winner: 0, loser: 0 });
  const [showEloChange, setShowEloChange] = useState(false);
  const [winnerId, setWinnerId] = useState(null);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [votingId, setVotingId] = useState(null);
  const [votingLocked, setVotingLocked] = useState(false);
  const [voteConfirmed, setVoteConfirmed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768 || window.innerHeight < 500);

  // Monitor window resize to determine if mobile view should be used
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768 || window.innerHeight < 500);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchMatchup = async () => {
    try {
      const response = await axios.get('https://us-central1-meme-ranking.cloudfunctions.net/api/matchup');
      setCurrentMatchup(response.data);
      setShowEloChange(false);
      setEloChange({ winner: 0, loser: 0 });
      setWinnerId(null);
      setSelectedMeme(null);
      setVotingId(null);
      setVotingLocked(false);
      setVoteConfirmed(false);
    } catch (error) {
      console.error('Error fetching matchup:', error);
    }
  };

  useEffect(() => {
    fetchMatchup();
  }, []);

  useEffect(() => {
    const handleBackNavigation = (event) => {
      if (selectedMeme) {
        event.preventDefault();  // Prevent default back behavior
        handleExitPreview();     // Close the meme preview instead
        window.history.pushState(null, null, window.location.href); // Add another state to keep user on the page
      }
    };

    if (selectedMeme) {
      window.history.pushState(null, null, window.location.href); // Push a state when entering preview
      window.addEventListener('popstate', handleBackNavigation);  // Listen to back navigation
    }

    return () => {
      window.removeEventListener('popstate', handleBackNavigation); // Clean up event listener
    };
  }, [selectedMeme]);

  const handleMemeClick = (meme) => {
    setSelectedMeme(meme);
  };

  const handleExitPreview = () => {
    setSelectedMeme(null);
  };

  const handleVote = async (selectedWinnerId) => {
    if (votingLocked) return;

    setVotingLocked(true);
    const loserId = currentMatchup[0].id === selectedWinnerId ? currentMatchup[1].id : currentMatchup[0].id;

    try {
      const response = await axios.post('https://us-central1-meme-ranking.cloudfunctions.net/api/match-result', {
        winnerId: selectedWinnerId,
        loserId,
      });

      const { newWinnerElo, newLoserElo } = response.data;

      const winnerEloChange = newWinnerElo - currentMatchup.find(meme => meme.id === selectedWinnerId).eloScore;
      const loserEloChange = newLoserElo - currentMatchup.find(meme => meme.id === loserId).eloScore;

      setEloChange({ winner: winnerEloChange, loser: loserEloChange });
      setCurrentMatchup(currentMatchup.map(meme => 
        meme.id === selectedWinnerId ? { ...meme, eloScore: newWinnerElo } : { ...meme, eloScore: newLoserElo }
      ));
      setWinnerId(selectedWinnerId);
      setShowEloChange(true);
      setVoteConfirmed(true);

      setTimeout(() => {
        fetchMatchup();
      }, 1000);

    } catch (error) {
      console.error('Error submitting vote:', error);
      setVotingLocked(false);
    }
  };

  const handleVoteClick = (memeId) => {
    if (votingLocked) return;

    if (votingId === memeId) {
      setVotingId(null);
      setVoteConfirmed(false);
    } else {
      setVotingId(memeId);
    }
  };

  const confirmVoteHandler = () => {
    if (!votingLocked) {
      handleVote(votingId);
      setVotingId(null);
    }
  };

  return (
    <div className="game-container">
      {currentMatchup.length === 2 ? (
        isMobile ? (
          <MobileView
            currentMatchup={currentMatchup}
            eloChange={eloChange}
            showEloChange={showEloChange}
            winnerId={winnerId}
            selectedMeme={selectedMeme}
            voteConfirmed={voteConfirmed}
            votingLocked={votingLocked}
            votingId={votingId}
            onMemeClick={handleMemeClick}
            onExitPreview={handleExitPreview}
            onVoteClick={handleVoteClick}
            onConfirmVote={confirmVoteHandler}
          />
        ) : (
          <DesktopView
            currentMatchup={currentMatchup}
            eloChange={eloChange}
            showEloChange={showEloChange}
            winnerId={winnerId}
            selectedMeme={selectedMeme}
            voteConfirmed={voteConfirmed}
            votingLocked={votingLocked}
            votingId={votingId}
            onMemeClick={handleMemeClick}
            onExitPreview={handleExitPreview}
            onVoteClick={handleVoteClick}
            onConfirmVote={confirmVoteHandler}
          />
        )
      ) : (
        <h2>Loading Matchup...</h2>
      )}
    </div>
  );
};

export default GamePage;

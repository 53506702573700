import React, { useState, useEffect } from 'react';
import './AvatarSelector.css';
import { fetchAvatars } from '../api/api';

const AvatarSelector = ({ currentAvatar, onAvatarChange, onClose }) => {
    const [avatars, setAvatars] = useState([]);

    // Fetch avatars from the API
    useEffect(() => {
        const loadAvatars = async () => {
            try {
                const avatarList = await fetchAvatars();
                setAvatars(avatarList);
            } catch (error) {
                console.error('Failed to load avatars:', error);
            }
        };
        loadAvatars();
    }, []);

    const selectAvatar = (avatarUrl, event) => {
        onAvatarChange(avatarUrl);
        onClose(); // Close the avatar selector
    };


    return (
        <div className="avatar-wheel">
            {avatars.map((avatar, index) => {
                const angle = (index / avatars.length) * (2 * Math.PI);
                let x, y;
                if (window.innerWidth < 768 || window.innerHeight < 500) {
                    x = Math.cos(angle) * 150;
                    y = Math.sin(angle) * 150;
                } else {
                    x = Math.cos(angle) * 320;
                    y = Math.sin(angle) * 320;
                }
                return (
                    <img
                        key={avatar.id}
                        src={avatar.url}
                        alt={`Avatar ${avatar.id}`}
                        className="avatar-option"
                        style={{
                            transform: `translate(${x}px, ${y}px)`
                        }}
                        onClick={() => selectAvatar(avatar.url)}
                    />
                );
            })}
            {/* <button className="close-btn" onClick={onClose}>Close</button> */}
        </div>
    );
};

export default AvatarSelector;

import React, { useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Menu from './components/Menu';
import GamePage from './pages/GamePage';
import LeaderboardPage from './pages/LeaderboardPage';
import LeaderboardPage_MOTM from './pages/LeaderboardPage_MOTM';  // Import MOTM Leaderboard
import GamePage_MOTM from './pages/GamePage_MOTM';  // Import MOTM GamePage
import ProtectedRoute from './components/ProtectedRoute';
import AvatarSelector from './components/AvatarSelector';
import './App.css';
import { useAuth } from './AuthContext';
import { updateUserProfile } from './api/api';
import defaultAvatar from './components/default-avatar.png';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);  // State for avatarUrl in App.js
  const [isAvatarSelectorOpen, setIsAvatarSelectorOpen] = useState(false); // Avatar selector state
  const { user, handleLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // To access query params

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleAvatarUpdate = (url) => {
    try {
      if (url !== avatarUrl) {
        setAvatarUrl(url);  // Update avatarUrl state in App.js
        updateUserProfile({ avatarFilePath: url });
      }
    } catch (error) {
      console.error('Error updating avatar URL:', error);
    }
  };

  const handleAvatarClick = () => {
    setIsAvatarSelectorOpen(true); // Open the avatar selector
  };

  const handleSelectorClose = () => {
    setIsAvatarSelectorOpen(false); // Close the avatar selector
  };

  // Extract the 'category' query parameter
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category') || 'all-time'; // Default to 'all-time' if no category specified

  return (
    <div className="app-background">
      <Menu
        isOpen={isMenuOpen}
        onToggle={toggleMenu}
        onNavigate={navigate}
        user={user}
        onLogout={handleLogout}
        avatarUrl={avatarUrl}  // Pass avatarUrl to Menu
        onAvatarUpdate={handleAvatarUpdate}  // Pass function to update avatarUrl
        onAvatarClick={handleAvatarClick} // Pass avatar click handler
      />
      <div className="main-content">
        <header className="app-header">
          <h1 className="app-title">
            <div className="parent-container">
              <span className="gradient-text">
                Meme Ranking {(window.innerWidth <= 768 || window.innerHeight <= 500) && <br />}Battle!
              </span>
            </div>
          </h1>
        </header>
        <Routes>
          {/* GamePage routing */}
          <Route
            path="/matchup"
            element={
              <ProtectedRoute>
                {category === 'meme-of-the-month' ? (
                  <GamePage_MOTM category={category} />  // Render MOTM GamePage
                ) : (
                  <GamePage category={category} />  // Render regular GamePage
                )}
              </ProtectedRoute>
            }
          />
          
          {/* LeaderboardPage routing */}
          <Route
            path="/leaderboard"
            element={
              <ProtectedRoute>
                {category === 'meme-of-the-month' ? (
                  <LeaderboardPage_MOTM category={category} />  // Render MOTM Leaderboard
                ) : (
                  <LeaderboardPage category={category} />  // Render regular LeaderboardPage
                )}
              </ProtectedRoute>
            }
          />
          
          {/* Default home route */}
          <Route
            path="/"
            element={
              <div className="centered-container">
                {user ? (
                  <>
                    <img
                      src={avatarUrl || defaultAvatar}
                      alt="User Avatar"
                      className="user-avatar"
                      onClick={handleAvatarClick} // Add click handler here
                    />
                    <h2 className="welcome-message">Welcome to Meme Ranking Battle, {user.displayName}!</h2>
                  </>
                ) : (
                  <h2 className="welcome-message">Welcome to Meme Ranking Battle!</h2>
                )}
              </div>
            }
          />
        </Routes>
        
        {/* Full-screen Avatar Selector */}
        {isAvatarSelectorOpen && (
          <AvatarSelector
            currentAvatar={avatarUrl || defaultAvatar}
            onAvatarChange={handleAvatarUpdate}
            onClose={handleSelectorClose}
          />
        )}
      </div>
    </div>
  );
};

export default App;
